.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.title-header {
  background-color: #282c34;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: white;
  margin: 0 0 20px 0;
}

.character-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.character-table {
  border:1px solid black;
  width: 80%;
}

.character-intable {
  border:1px solid black;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-normal {
  width: 200px;
  font-size: 16px;
  margin: 5px 0;
  cursor: pointer;
}

.button-delete {
  width: 200px;
  background-color: red;
  color: white;
  font-size: 20px;
  margin: 5px 0;
  cursor: pointer;
}

.form-container {
  margin: 30px 0 0 0;
}

.form-changepassword {
  margin: 0 0 15px 0;
}

.alert-text {
  color: red;
  font-size: 16px;
}

.success-text {
  color: green;
  font-size: 20px;
}
